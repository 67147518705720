import React, {useContext} from "react";
import {useErrorHandler} from "react-error-boundary";
import {HC_API} from "../utils/params";
import axios from "axios";
import Cookies from "universal-cookie/lib";
import useAlert from "./useAlert";
import ModalContext from "../context/modal/modalContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import useUser from "./useUser";
const cookies = new Cookies();


const undefinedResponse = res => {
    return res === undefined || res.data === undefined
}

let interceptorRequest = ''
let interceptorResponse = ''


 export const useApi = () => {

     const handleError = useErrorHandler()
     const {logout} = useUser();

     const {setAlert} = useAlert();
     const api = async (
         { route, data = {}, headers = {}, config = {},
             errorMsg = '', successMsg = '' }) => {
         if (!('Authorization' in headers)) {
             headers.Authorization = 'Cognito ' + cookies.get('awst');
         }
         return callApi(route, data, headers, config,errorMsg,successMsg)
     }
     const ssoApi = async ({
                               ssoId, route, data={},
                               headers={}, config={},
                               successMsg = '', errorMsg = ''
                           }) => {
         if (!('Authorization' in headers)) {
             headers.Authorization = 'SSO ' + ssoId;
         }
         return callApi(route, data, headers, config,errorMsg,successMsg)
     }
     const medicalApi = async ({
         route,data = {},
         headers = {}, config = {},
         errorMsg = '', successMsg = ''
     }) => {
         if (!('Authorization' in headers)) {
             headers.Authorization = 'Medical ' + cookies.get('medicalToken');
         }
         return callApi(route, data, headers, config,errorMsg,successMsg)
     }

    const callApi = async (route, data={},headers={},config={},errorMsg,successMsg) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        // Full screen setting will cause the loader to cover/block the main navigation
        let blackoutClass = 'blackoutDefault';

        if (config.loader && config.loader.full_screen) {
            blackoutClass = 'blackoutFull';
        }

        try {
            let tmpConfig = {
                headers,
                cancelToken: source.token,
                timeout: 300000
            };
            let mergedConfig = {
                ...config,
                ...tmpConfig
            }
            if (!('Content-Type' in mergedConfig.headers))
                mergedConfig.headers['Content-Type'] = 'application/json';

            if(mergedConfig.headers['Content-Type'] === 'multipart/form-data')
                data.append('remote_uri', window.location.href);
            else
                data.remote_uri = window.location.href;

            let blackout = mergedConfig.blackout !== undefined ? mergedConfig.blackout : true;
            let blackoutElement;

            if(!document.getElementById("blackout")) {
                blackout = false;
            }

            if(blackout) {
                blackoutElement = document.getElementById("blackout");
            }

            const url = HC_API + route;

            if (blackout){
                interceptorRequest = axios.interceptors.request.use((mergedConfig) => {
                        blackoutElement.classList.add(blackoutClass);
                    return mergedConfig;
                }, (error) => {
                        blackoutElement.classList.remove(blackoutClass);
                    return Promise.reject(error);
                });

                interceptorResponse = axios.interceptors.response.use((response) => {
                        blackoutElement.classList.remove(blackoutClass);
                    return response;
                }, (error) => {
                        blackoutElement.classList.remove(blackoutClass);
                    return Promise.reject(error);
                });
            } else {
                removeInterceptors();
            }


            const res = await axios.post(url, data, mergedConfig)
            if (undefinedResponse(res) || res.data.status !== "success") {
                if (errorMsg !== '') setAlert(errorMsg,'danger');
                return res;
            } else {
                if (successMsg !== '') setAlert(successMsg,'success',true,15000)
                return res;
            }

        } catch (e) {
            const res = e.response

            const {status = 0, data} = res || '';

            if (status === 401) {
                logout();
            } else if (status === 500) {
                return res
            } else {
                console.error('API Error: ',e);
                handleError(e);
            }

        }
    }



    return {ssoApi,api,medicalApi}

}
const removeInterceptors = () => {
    axios.interceptors.request.eject(interceptorRequest)
    axios.interceptors.response.eject(interceptorResponse)
    axios.interceptors.request?.handlers.splice(0, axios.interceptors.response?.handlers.length)
    axios.interceptors.response?.handlers.splice(0, axios.interceptors.response?.handlers.length)
}

export const exportApi = () => {
    const {t,i18n} = useTranslation('common');
    const modalContext = useContext(ModalContext);
    const {setModal} = modalContext;

    const exportData = async ({
         route, data, headers={}, config={},
         successMsg = '', errorMsg = '', callback
     }) => {
        if (!('Authorization' in headers) && config.src === 'sso') {
            headers.Authorization = 'SSO ' + config.ssoId;
        } else if (!('Authorization' in headers)) {
            headers.Authorization = 'Cognito ' + cookies.get('awst');
        }

        // remove interceptors for export function
        removeInterceptors();
        const controller = new AbortController();
        const url = HC_API + route;

        data.remote_uri = window.location.href;

        config.headers = headers;
        config.signal = controller.signal;

        const cancelExport = () => {
            controller.abort()
        }

        const popup = (modalBody,btnText = 'Cancel') => {
            // remove interceptors for export function
            setModal("Export Records", modalBody, '', 'exportDialog', false, {
                static_bg: false,
                animation: false,
                size: 'md',
                scrollable: false,
                onExit: '',
                btnClose: {
                    func: function() { cancelExport(); },
                    text: btnText,
                },
            });
        }

        const loadingAnimation = './assets/images/loading.gif';

        const modalBody = (<>
            <div className={'exportSuccess'}>{t('csvExport.exporting')}
                <p><img src={loadingAnimation} alt="loading" /></p>
            </div>
            <div className={'exportNote'}>{t('csvExport.note')}</div>
            </>
        );

        const modalBodySuccess = (<> <div className={'exportSuccess'}>{t('csvExport.complete')}</div>
        <div className={'exportSuccessIcon'}>
            <FontAwesomeIcon title={'Export Done'}
                icon={['fa','check-circle']}
                size={'6x'} />
        </div>
        <div className={'exportNote'}>{t('csvExport.note')}</div>
        </>);

        const modalBodyFail = (<>
            <div className={'exportFail'}>{t('csvExport.failed')}</div>
            <div className={'exportFailIcon'}>
                <FontAwesomeIcon title={'Export Failed'}
                                 icon={['fa','times-circle']}
                                 size={'6x'} />
            </div>
        </>);

        const modalBodyCancel = (<>
            <div className={'exportFail'}>{t('csvExport.canceled')}</div>
            <div className={'exportFailIcon'}>
                <FontAwesomeIcon title={'Export Canceled'}
                                 icon={['fa','times-circle']}
                                 size={'6x'} />
            </div>
        </>);

        popup(modalBody,'Cancel');

        try {
            const res = await axios.post(url, data, config);
            if (undefinedResponse(res) || res.data.status !== "success") {
                popup(modalBodyFail, 'Close');
                return res;
            } else {
                popup(modalBodySuccess,'Close');
                res.data.data = res.data.data.map(({metadata, ...keepAttrs}) => keepAttrs)
                return res;
            }
        } catch(e) {
            if (controller.signal.aborted) {
                popup(modalBodyCancel, 'Close');
            } else {
                popup(modalBodyFail, 'Close');
            }
            return false;
        }

    }
    return {exportData}
}